import axios from '@axios'

export default {
  namespaced: true,
  state: {
    certifications: [],
    productCategory: [],
    serviceCategory: [],
    blogCategory: [],
  },
  getters: {},
  mutations: {
    GET_PRODUCT_CATEGORIES(state, productCategory) {
      state.productCategory = productCategory
    },
    GET_CERTIFICATE_CATEGORIES(state, certifications) {
      state.certifications = certifications
    },
    GET_SERVICE_CATEGORIES(state, serviceCategory) {
      state.serviceCategory = serviceCategory
    },
    GET_BLOG_CATEGORIES(state, blogCategory) {
      state.blogCategory = blogCategory
    },
  },
  actions: {
    getProductCategory({ commit }) {
      axios.get('https://gulfdb.technocloud.live/api/get/product/selected_category')
        .then(response => {
          commit('GET_PRODUCT_CATEGORIES', response.data.success)
        }).catch(error => console.log(error))
    },
    getCertificationsCategory({ commit }) {
      axios.get('https://gulfdb.technocloud.live/api/get/selected_certificate')
        .then(response => {
          commit('GET_CERTIFICATE_CATEGORIES', response.data.success)
        }).catch(error => console.log(error))
    },
    getServiceCategory({ commit }) {
      axios.get('https://gulfdb.technocloud.live/api/get/service/selected_category')
        .then(response => {
          commit('GET_SERVICE_CATEGORIES', response.data.success)
        }).catch(error => console.log(error))
    },
    getBlogCategory({ commit }) {
      axios.get('https://gulfdb.technocloud.live/api/get/blog/selected_category')
        .then(response => {
          commit('GET_BLOG_CATEGORIES', response.data.success)
        }).catch(error => console.log(error))
    },
  },
}
