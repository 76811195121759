import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Axios from 'axios'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Axios.defaults.baseURL = 'https://gulfdb.technocloud.live/api/'
Axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`
Axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
