export default [
  {
    path: '/services-categories/list',
    name: 'services-categories',
    component: () => import('@/views/categories/services-categories/ServicesCategories.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/products-categories/list',
    name: 'products-categories',
    component: () => import('@/views/categories/product-categories/ProductCategory.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/blog-categories/list',
    name: 'blog-categories',
    component: () => import('@/views/categories/blog-categores/BlogCategoriesList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/certifications',
    name: 'certifications',
    component: () => import('@/views/certifications/Certifications.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
