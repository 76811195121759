export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/products/list',
    name: 'products',
    component: () => import('@/views/products/products-list/ProdutsList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/products/add',
    name: 'add-product',
    component: () => import('@/views/products/products-add/AddProducts.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/products/edit/:id',
    name: 'edit-product',
    component: () => import('@/views/products/products-edit/EditProduct.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/services/services-list/ServicesList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/services/edit/:id',
    name: 'edit-services',
    component: () => import('@/views/services/services-edit/EditServices.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/services/add',
    name: 'add-services',
    component: () => import('@/views/services/services-add/AddService.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events/list',
    name: 'events',
    component: () => import('@/views/events/events-list/Events.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events/add',
    name: 'add-event',
    component: () => import('@/views/events/add-event/AddEvent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events/edit/:id',
    name: 'edit-event',
    component: () => import('@/views/events/edit-event/EditEvent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/About.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/blog/blog-list/BlogList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/blog/edit/:id',
    name: 'edit-blog',
    component: () => import('@/views/blog/blog-edit/EditBlog.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/blog/add',
    name: 'add-blog',
    component: () => import('@/views/blog/blog-add/AddBlog.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/slider',
    name: 'slider',
    component: () => import('@/views/slider/Slider.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/contact/contact-list/Contact.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/footer',
    name: 'footer',
    component: () => import('@/views/footer/Footer.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/support/Support.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/headers',
    name: 'headers',
    component: () => import('@/views/headers/Headers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/languages',
    name: 'languages',
    component: () => import('@/views/languages/Languages.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
